@import '../../theme/variables.scss';

.pin-page .login {
  height: 25%;

  h3 {
    color: #fff;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 20px;
    margin-bottom: 1rem;
    z-index: 10;
  }
}

.pin-page {
  .feedback {
    background: var(--ion-color-primary);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    font-size: 2.4rem;
  }

  .pad {
    height: 75%;
    height: calc(75% - env(safe-area-inset-bottom));
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > span {
      width: 33.3333%;
      height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 2.6rem;
      transition: all linear 0.2s;
      position: relative;

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
    }
  }
}

.active {
  .animation {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    opacity: 0.2;
    animation: touch 0.6s linear;
  }
}

@keyframes touch {
  from {
    border: 1px solid #fff;
    background: #fff;
  }
  to {
    border: 0 solid #fff;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.pad-1,
.pad-4,
.pad-7 {
  border-bottom: 1px solid transparent;
  border-right: 0px solid transparent;
  border-image: linear-gradient(145deg, transparent 60%, rgba(255, 255, 255, 0.3));
  border-image-slice: 1;
}

.pad-3,
.pad-6,
.pad-9 {
  border-left: 0px solid transparent;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(-145deg, transparent 60%, rgba(255, 255, 255, 0.3));
  border-image-slice: 1;
}

.pad-2 {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(180deg, transparent 10%, rgba(255, 255, 255, 0.3));
  border-image-slice: 1;
}

.pad-5,
.pad-8 {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.pad-0 {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 0px solid transparent;
  border-image: linear-gradient(0deg, transparent 10%, rgba(255, 255, 255, 0.3));
  border-image-slice: 1;
}
