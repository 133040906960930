.bg-layer {
  background-image: url('/assets/bg.jpg');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(15px);
  -webkit-filter: blur(15px);
  position: absolute;
  top: 0;
}

.bg-black {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
}

ion-item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --inner-padding-end: 0;

  ion-thumbnail {
    --border-radius: 5px;
    margin-right: 1rem;
  }
}
