.photo-page {
  .header {
    background-color: #000;
    color: #fff;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-right: 1rem;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    text-transform: uppercase;

    &.confirm {
      background: rgba(var(--ion-color-primary), 0.7);
    }

    >div {
      display: flex;
      align-items: center;

      ion-icon {
        font-size: 2rem;
        padding: 1.5rem 2rem;
      }
    }

    p {
      margin: 0 0 0 0.5rem;
    }
  }

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    >div {
      // position: fixed;
      width: 100%;
      z-index: 1;
    }

    ion-button {
      --border-radius: 50%;
      width: 200px;
      height: 200px;
      font-size: 3rem;
    }

    img {
      width: 200px;
      border-radius: 5px;
    }
  }
}

.time {
  color: #fff;
  margin: 3rem 0;
  margin-top: 0;

  span {
    font-size: 6rem;
    margin-bottom: 2rem;
  }
}

.body {
  p {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.body ion-item {
  --padding-start: 8px;
  --padding-end: 8px;

  ion-thumbnail {
    width: 75px;
    height: 90px;
  }

  ion-label {
    font-weight: normal !important;
  }

  span {
    font-size: 2.5rem;
  }
}

.shift-end {
  color: #9a9292;
  margin-top: 3rem;

  span {
    font-size: 4rem;
  }
}

.ios {
  .body ion-item ion-thumbnail {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.crop {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #3a3535;
  backdrop-filter: blur(10px);

  .cropper-container {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.crop-rotate {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}