/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  --ion-color-primary: #ed202e;
  --ion-color-primary-rgb: 237, 32, 46;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: rgba(255, 255, 255, 1);
  --ion-color-primary-shade: #d11c28;
  --ion-color-primary-tint: #ef3643;

  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 255, 228, 67;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: rgba(255, 255, 255, 1);
  --ion-color-secondary-shade: #1b1e20;
  --ion-color-secondary-tint: #35383a;

  --ion-color-tertiary: #1f2224;
  --ion-color-tertiary-rgb: 31, 34, 36;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: rgba(255, 255, 255, 1);
  --ion-color-tertiary-shade: #1b1e20;
  --ion-color-tertiary-tint: #35383a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc209;
  --ion-color-warning-rgb: 255, 194, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ab08;
  --ion-color-warning-tint: #ffc822;

  --ion-color-danger: #eb5844;
  --ion-color-danger-rgb: 235, 88, 68;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #cf4d3c;
  --ion-color-danger-tint: #ed6957;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #353230;
  --ion-color-medium-rgb: 53, 50, 48;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #2f2c2a;
  --ion-color-medium-tint: #494745;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --border-radius: 5px;
}
