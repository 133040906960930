.login-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;

  p {
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
}

.login ion-item {
  width: 100%;
  --border-radius: 5px;

  ion-input {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 1rem !important;
    --padding-end: 1rem !important;
    font-size: 25px;
    opacity: 0.8;
  }
  .login-input {
    --padding-end: 0 !important;
  }
}

.login ion-img {
  width: 80%;
  margin-bottom: 1rem;
}

.login ion-label {
  background-color: #f1f1f1;
  color: var(--ion-color-dark) !important;
  font-weight: 600;
  margin: 0;
  line-height: 48px;
  padding: 0 1rem;
  font-size: 14px !important;
}

.ios {
  .login ion-input {
    --padding-start: 1rem;
  }
}
